import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import Crown from '../../asserts/crown.png'
import Phone from '../../asserts/system-regular-58-call-phone.gif'
import Mail from '../../asserts/wired-outline-177-envelope-send.gif'
import Facebook from '../../asserts/facebook.png'
import Insta from '../../asserts/instagram.png'
import YouTube from '../../asserts/youtube.png'
import { axiosInstance } from '../Api/instance'
import { useEffect,useState } from 'react'
import Logo from '../../asserts/Artboard 2.png'
import { NavLink } from 'react-router-dom'
import { useContext } from 'react'
import { UserContext } from '../../Context' 
import './index.css'
import { useLocation } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';
import { useNavigate } from 'react-router-dom'
export default function Footer() { 
    const [data, setData] = useState([]);
    const {Endpoint}=useContext(UserContext);
    const email=localStorage.getItem("userMail");
    const Navigate=useNavigate();
    const location = useLocation();

    const getHref = (path) => {
        return location.pathname === "/home" ? `#${path}` : `/home/#${path}`;
    };
    useEffect(() => {
      fetchData();
    }, []);

    const fetchData = async () => {
      try {
        const response = await axiosInstance.post(
          `get/U_ViewStaticInfo.php`,
          {
            userId:email
          }
        );
        if (response.status === 200) {
        if (response.data.message === "timeout") {
            Navigate('/signin'); 
        }
            setData(response.data); 
    }
        
      } catch (error) {
       
        console.error("Error fetching data:", error);
      }
    };

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = '/app-release.apk'; 
        link.download = 'NursingUpdate.apk'; 
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };
    const Col1={
        margin:"10px",
        color:"white",
        display:"flex",
        justifyContent:"space-between"
    }
    const MainContainer={
        backgroundColor:"black",
        borderTopLeftRadius:"20px",
        borderTopRightRadius:"20px"
    }
  return (
    <div>
    <Container fluid style={MainContainer}>
        <Row>
            <Col xs={12}>
                <div style={Col1} >
                <div>
                <img src={Logo} height="50px"/>
                <b> Nursing Update</b> 
                </div>
               <div style={{backgroundColor:"white",color:"black",padding:"10px"}}>
                   <button  onClick={handleDownload} style={{border:"none",backgroundColor:"white"}}> Download App <DownloadIcon/> </button>
                </div>
                </div>
                
            </Col>
        </Row>
        <Row style={{paddingLeft:"10px",marginTop:"10px"}}>
            <Col xs={12} sm={5} md={2} lg={2} xl={2}>
            <div style={{color:"white"}}>
                content
                <div> 
                <ul style={{ listStyleType: "none", color: "gray", paddingLeft: "0px" }}>
                <li><a href={getHref("category")} className='nav'>Category</a></li>
            
            <NavLink to="/about" className='nav'>
                <li>About</li>
            </NavLink>
            <NavLink to="/courses" className='nav'>
                <li>Courses</li>
            </NavLink>
           
                <li><a href={getHref("ad")} className='nav'>Achievements</a></li>
           
        </ul>
                </div>
            </div>
            </Col>
            <Col xs={12} sm={7} md={3} lg={3} xl={3}>
            <div style={{color:"white"}}>
                Contact Us
                <div>
                {Array.isArray(data) && data.map((d) => (
    <ul style={{ listStyleType: "none", color: "gray", paddingLeft: "0px" }}>
        <li><img src={Mail} height="25px" alt="mail" />&nbsp;{d.gmaillink}</li>
        <li><img src={Phone} height="25px" alt="phone" />&nbsp;{d.mobibleno}</li>
    </ul>
))}

                </div>
            </div>
            </Col>
            <Col xs={12} sm={5} md={2} lg={2} xl={2}>
            <div style={{color:"white"}}>
                Get Started
                <div>
                    <ul style={{listStyleType:"none",color:"gray",paddingLeft:"0px"}}>
                        <li>Pricing</li>
                        <li>Free Trail</li>
                        <li>Contact us</li>
                    </ul>
                </div>
            </div>
            </Col>
            <Col xs={12} sm={7} md={2} lg={2} xl={2}>
            <div style={{color:"white",marginBottom:"20px"}}>
                Stay Connected
                <div style={{marginTop:"3px"}}>
                    <a href='https://www.instagram.com/nursingupdate2023?igsh=MWx3bXkzOG1kbGR6' target='_blank'><img src={Insta} height="30px"/></a>
                    &nbsp;&nbsp;<a href='https://www.facebook.com/profile.php?id=100079218203603' target='_blank'><img src={Facebook} height="30px"/></a>
                    &nbsp;&nbsp;<a href='https://youtube.com/@nursingupdate-tamil?si=5kG_1oBOkE9nyJAz' target='_blank'><img src={YouTube} height="30px"/></a>

                </div>
            </div>
            </Col>
            <Col xs={12} sm={12} md={3} lg={3} xl={3} >
            <NavLink to="/plan" style={{textDecoration:"none",color:"black"}}>
            <div style={{backgroundColor:"white",height:"50px",fontWeight:800,textAlign:"center",borderRadius:"10px",paddingTop:"10px",fontSize:"20px"}}>
                Premium Plans
                &nbsp;<img src={Crown} height="25px" style={{marginBottom:"8px"}} />
            </div>
            </NavLink>
            </Col>
        </Row>  
        <p style={{color:"gray",padding:"10px"}}> Nursing Update All rights reserved</p>
    </Container>
    
    </div>
  )
}
