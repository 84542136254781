import { Typography } from "@mui/material";
import React from "react";
import {Container,Row,Col, Button} from 'react-bootstrap'
import Arrow from '../asserts/system-regular-161-trending-flat.gif'
import AnswerBtn from "./AnswerBtn";
import DialogContentText from '@mui/material/DialogContentText';
import Share1 from '../asserts/system-regular-1-share.gif'
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Correct from '../asserts/Component 322 – 3.png'
import Rong from '../asserts/Component 26 – 5.png'
import Web from '../asserts/_x36__stroke.png'
import { useState,useEffect,useContext } from "react";
import { axiosInstance } from "../component/Api/instance";
import { UserContext } from "../Context";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
function SubTest() {
  const MainContainer={
    
    // overflowX:"hidden"
  }
 
  const Btn={
    backgroundColor:"white",
    color:"black",
    border:"none",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    width:"150px",
    fontWeight:600,
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
  }
  
  
  const Btn1={
    backgroundColor:"white",
    color:"black",
    border:"1px solid #183A1D ",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    width:"150px",
    fontWeight:600,
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
  }
  const PreviewBtn={
    backgroundColor:"#F0A04B",
    color:"white",
    border:"none",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    fontWeight:600,
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    padding:"7px",
    borderRadius:"5px"
  }
  const AlertBtn={
    backgroundColor:"white",
    color:"black",
    border:"1px solid black",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    fontWeight:600,
    display:"flex",
    justifyContent:"center",
     alignItems:"center",
      padding:"7px",
    borderRadius:"5px",
    width:"100px"
  }
  const Share={
    backgroundColor:"#183A1D",
    color:"white",
    border:"none",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    fontWeight:600,
    display:"flex",
    justifyContent:"center",
     alignItems:"center",
      padding:"7px",
    borderRadius:"5px"
  }
  const Finsh={
    backgroundColor:"#183A1D",
    color:"white",
    border:"none",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    fontWeight:600,
    display:"flex",
    justifyContent:"center",
    marginTop:"30px",
    width:"200px",
    alignItems:"center"
  }

  const noCopyStyle = {
    userSelect: "none",
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    MsUserSelect: "none",
    fontFamily:"Answer"
    
  };
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const {sno,paperid}=useParams();
  const [options,setOptions]=useState([]);
  const [questions, setQuestions] = useState([]);
  const [questionsNo, setQuestionsNo] = useState([]);
  const [questioncount, setQuestioncount] = useState()
  const [responseData, setResponseData] = useState(null);
  const [responsecount, setResponsecount] = useState(null);
  const email=localStorage.getItem("userMail");
  const [skippedQuestions, setSkippedQuestions] = useState([]);
  const [questionStatus, setQuestionStatus] = useState([]);
  const [whatsapp,setWhatsapp]=useState([]);
  const location = useLocation();
  const { Endpoint } = useContext(UserContext);
  const navigate = useNavigate();
  const paperName = location.state?.paperName;
  useEffect(()=>{
    fetchQuestionSno();
  },[])

  const fetchQuestions = async (questionId) => {
    try {
      const res = await axiosInstance.post(
        `get/U_ViewSubWiseQuestions.php`,
        {
          userId:email,
          subjectId: sno,
          paperId: paperid,
          questionId: questionId
        }
      );
      if (res.status === 200) {
        if (res.data.message === "timeout") {
          navigate('/signin'); 
        } 
          setQuestions(res.data);
      console.log(res.data);
     
       
      }
      
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };
 
  const [Previewopen, setPreviewopen] = React.useState(false);

  const PreviewhandleClickOpen = () => {
    setPreviewopen(true);
  };
  const PreviewhandleClose = () => {
    setPreviewopen(false);
    navigate("/subject");
  };

  const [open, setOpen] = React.useState(false);
  const [finish,setfinish]=React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const FinishTest=()=>{
    setfinish(true);
  }
  const FinishTestClose=()=>{
    setfinish(false);
    navigate("/subject");
          
  }
  
  const [CrtBtnopen, setCrtBtnOpen] = React.useState(false);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(null); // Define selected option index state
  const [selectedOption, setSelectedOption] = useState("");
  const CrtClickOpen = () => {
    setCrtBtnOpen(true);
  };

  const CrtClickClose = () => {
    setCrtBtnOpen(false);
  };
  const styles = {
    mainContainer: {
      width:'100%',
      marginLeft:0,
      display:"flex",
      justifyContent: "space-between",
      backgroundColor: "white",
      height:"50px",
      borderTopRightRadius: "10px",
      borderTopLeftRadius: "10px",
      
    },
    title: {
      display: "flex",
      flexDirection: "row",
      width:'95vw',
      marginLeft:'-10px',
     justifyContent:"space-between",
      padding: "10px 10px",
      zIndex: "1",

    },
  }
  const registerDatas = async () => {
   
    try {
      const res = await axiosInstance.post(
        `get/U_viewSubjectPreview.php`,
        {
          userId:email,
          subject_id: sno,
          paper_id: paperid,
        }
      );
      if (res.status === 200) {
        if (res.data.message === "timeout") {
          navigate('/signin'); 
        } 
          if (Array.isArray(res.data)) {
            setResponseData(res.data);
          } else {
            console.error("Unexpected data format:", res.data);
          }
       
      }
      
    } catch (err) {
     
      console.error("Error fetching data:", err);
    }
  };
  const registerDatas1 = async () => {
   
    try {
      const res = await axiosInstance.post(
        `get/U_SubjectMark.php`,
        {
          userId:email,
          category_id:sno,
          paper_id:paperid
        }
      );
      if (res.status === 200) {
        if (res.data.message === "timeout") {
          navigate('/signin'); 
        } 
          if (Array.isArray(res.data)) {
            setResponsecount(res.data);
            console.log(res.data);
            
          } else {
            console.error("Unexpected data format:", res.data);
          }
      
      }
      
    } catch (err) {
     

      console.error("Error fetching data:", err);
    }
  };
  const handleQuestionChange = async (index) => {
    if (index >= 0 && index < questionsNo.length) {
      const selectedSno = questionsNo[index].sno;
      console.log("Selected question sno:", selectedSno);
      fetchQuestions(selectedSno);
      setSelectedQuestionIndex(index);
  
      // Check if it's the last question
      if (index === questionsNo.length - 1) {
        await registerDatatest(questionsNo[selectedQuestionIndex].sno);
      }
    }
  };
  const fetchQuestionSno = async () => {
    try {
      const res = await axiosInstance.post(
        `get/U_ViewSubWiseQuestionCount.php`,
        {
          userId:email,
          subjectId: sno,
          paperId: paperid,
        }
      );
      if (res.status === 200) {
        if (res.data.message === "timeout") {
          navigate('/signin'); 
        } 
          setQuestionsNo(res.data);
          const count = res.data.length;
          setQuestioncount(count);
          console.log(res.data);
          setSelectedQuestionIndex(0);
       
      }
     
    } catch (error) {
      console.error("Error fetching question numbers:", error);
    }

  };
  const handleNextQuestion = async () => {
    const nextIndex = selectedQuestionIndex + 1;
    if (nextIndex < questionsNo.length) {
      const selectedSno = questionsNo[nextIndex].sno;
      await registerDatatest(questionsNo[selectedQuestionIndex].sno);
      await fetchQuestions(selectedSno);
      setSelectedQuestionIndex(nextIndex);
      setSelectedOption('');
      setSelectedOptionIndex(null);
      setQuestionStatus(prevStatus => {
        const newStatus = [...prevStatus];
        if (selectedOption) {
          newStatus[selectedQuestionIndex] = 'answered';
        } else {
          newStatus[selectedQuestionIndex] = 'skipped'; 
        }
        return newStatus;
      });
    } else if (nextIndex === questionsNo.length) {
    
      await registerDatatest(questionsNo[selectedQuestionIndex].sno);
      setQuestionStatus(prevStatus => {
        const newStatus = [...prevStatus];
        newStatus[selectedQuestionIndex] = selectedOption ? 'answered' : 'skipped';
        return newStatus;
      });
     
    }
  };
 
  const registerDatatest = async (questionId, answer) => {
    try {
      const res = await axiosInstance.post(
        `put/U_updateSubTestAns.php`,
        {
          id: email,
          question_id: questionId,
          answer: selectedOption
        }
      );
      if (res.status === 200) {
        if (res.data.message === "timeout") {
          navigate('/signin'); 
        } 
          console.log(res.data);
      setSelectedOption('');
       
      }
     
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };
  useEffect(() => {
    Institution();
  }, []);

  const Institution = async () => {
    try {
      const response = await axiosInstance.post(
        `get/U_ViewStaticInfo.php`,
        {
          userId: email
        }
      );
      if (response.status === 200) {
        if (response.data.message === "timeout") {
          navigate('/signin'); 
        } 
          setWhatsapp(response.data);
       
      }
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  const handleSkipQuestion = async () => {
 
    setQuestionStatus(prevStatus => {
      const newStatus = [...prevStatus];
      newStatus[selectedQuestionIndex] = 'skipped';
      return newStatus;
    });
  
  
    await handleNextQuestion();
  };
  
  
  
   const handleOptionSelect = (optionIndex) => {
    const selectedOptionText = questions[0][`option${optionIndex}`];
    setSelectedOptionIndex(optionIndex); 
    setSelectedOption(selectedOptionText); 
  
    console.log("Selected option data:", selectedOptionText); 
  };
  return (
    <div style={{ backgroundColor:"#F2F1EB",padding:"20px",height:"100vh"}}>
      <Container fluid style={styles.mainContainer}>
                      <Row >
                        <Col >
                          <div className="wrap" style={styles.title}>
                            <div>
                              <Typography style={{ fontWeight: 600 }}>{paperName}</Typography>
                             </div>
                            <div>
                              <Typography style={{ fontWeight: 600 }}>
                              {selectedQuestionIndex + 1}/{questionsNo.length}
                              </Typography>
                            </div>
                          </div>
                        </Col>
                      </Row>
        </Container>
    <div >
     
     
      <Container fluid style={MainContainer}>
        <Row >
          <Col xs={12} sm={12} md={12} lg={7} xl={7} style={{ backgroundColor: "#E1EEDD" }}>
            <div style={{ paddingTop: "30px" }}>
            
              <div>
              
                    {questions[0] ? (
                <div  style={noCopyStyle}>
                  <p style={{fontWeight:"bold",letterSpacing:"1px"}} >{`${selectedQuestionIndex + 1}. ${questions[0].questions}`}</p>
                  <ol type="a" style={{ paddingLeft: "0", listStylePosition: "inside" }}>
  {[1, 2, 3, 4].map((option) => (
    <li key={option} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
      <label style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        <input
          type="radio"
          name="options"
          value={option}
          checked={selectedOptionIndex == option}
          onChange={() => handleOptionSelect(option)}
          style={{ marginRight: "10px" }}
        />
        {questions[0][`option${option}`]}
      </label>
    </li>
  ))}
</ol>

                </div>
              ) : (
                <p>No question selected</p>
              )}
              </div>


             
              <div style={{display:"flex",justifyContent:"flex-end",alignItems:"flex-end",height:"300px",marginBottom:"20px"}}>
                <Button style={Btn}  onClick={handleSkipQuestion}>Skip</Button>&nbsp;&nbsp;&nbsp;
                <Button style={Btn} onClick={handleNextQuestion}>Save & Next <img src={Arrow} height="25px"/></Button>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={5} xl={5} style={{backgroundColor:"#FEFBE9",zIndex:1,}} >
            <Typography style={{fontWeight:600,textAlign:"center",paddingTop:"30px"}}>QUESTION STATUS</Typography>
            <div style={{ paddingTop: "60px", width: "auto", marginTop: "30px", padding: "20px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex" }}>
                <div style={{ backgroundColor: "#183A1D", height: "20px", width: "20px", borderRadius: "50%" }}></div>
                &nbsp;&nbsp;<span style={{ marginTop: "-3px", fontWeight: 600 }}>Answered</span>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ backgroundColor: "red", height: "20px", width: "20px", borderRadius: "50%" }}></div>
                &nbsp;&nbsp;<span style={{ marginTop: "-3px", fontWeight: 600 }}>Skipped</span>
              </div>
            </div>
              <div
              style={{
                backgroundColor: "#f6f6f6",
                borderRadius: "15px",
                overflow: "auto",
                width: "100%",
                paddingLeft: "40px",
                marginTop: "20px",
                display: "flex",
                flexWrap: "wrap",
                height:"300px"
              }}
            >
              {questionsNo.map((question, index) => (
                <button
                  key={index}
                  style={{
                    backgroundColor: questionStatus[index] === 'skipped' ? 'red' :
                      questionStatus[index] === 'answered' ? 'green' :
                        'white',
                    color: 'black',
                    border: 'none',
                    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                    width: '50px',
                    height: '50px',
                    fontWeight: 600,
                    marginBottom: '10px',
                    marginTop: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '50%',
                    padding: '10px',
                    marginLeft: '30px'
                  }}
                  onClick={() => handleQuestionChange(index)}
                >
                  {index + 1}
                </button>
              ))}
            </div>

                <div >
                    
                <div className="d-grid " style={{display:"flex",justifyContent:"center"}}>
                    <Button style={Finsh} onClick={handleClickOpen}>FINISH TEST</Button>
                    </div>
                    
                    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           Are you sure you want to finish the Test ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
                    <Button style={Share} onClick={() => {
                      FinishTest();
                      registerDatas1();

                    registerDatas(); 
                }}>Yes,Finish Test</Button>
          <Button style={AlertBtn} onClick={handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={finish}
        onClose={FinishTestClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          
        </DialogTitle>
        <DialogContent>
                    <DialogContentText style={{ fontWeight: 600, color: "black" }}>
                      Thank you, Your test is submitted successfully.
                      {responsecount ? (
                        responsecount.length > 0 ? (responsecount.map((item, index) => (
                        <div key={index}>
                          <Typography style={{ fontWeight: 500, color: "black" }}>
                              Correct Answer: {item.correct_count}
                          </Typography>
                          <Typography style={{ fontWeight: 500, color: "black" }}>
                            Wrong Answer: {item.incorrect_count}
                          </Typography>
                          <Typography style={{ fontWeight: 500, color: "black" }}>
                            Skipped: {item.skip_count}
                          </Typography>
                          <Typography style={{ fontWeight: 500, color: "black" }}>
                            Negative Mark: {item.negative_marks}
                          </Typography>
                          <Typography style={{ fontWeight: 500, color: "black" }}>
                            Total Mark: {item.total_marks}
                          </Typography>
                          
                        </div>
                        ))
                        ) : (
                          <Typography>No data available</Typography>
                        )
                      ) : (
                        <Typography>Loading data...</Typography>
                      )}
                    </DialogContentText>
          
        </DialogContent>
        <DialogActions style={{display:"flex",justifyContent:"space-around"}}>
        <button  style={PreviewBtn} onClick={PreviewhandleClickOpen}><VisibilityIcon style={{fontSize:"20px",marginTop:"4px"}}/>&nbsp; Preview</button>
          <button style={Share}><img src={Share1} height="20px" style={{marginTop:"4px"}}/>&nbsp; <a href="https://www.instagram.com/" target="_blank" style={{color:"white",textDecoration:"none"}}>Share Score</a> </button>
          <button style={AlertBtn} onClick={FinishTestClose} autoFocus>
            Done
          </button>
        </DialogActions>
      </Dialog>
    <div>
    <Dialog onClose={PreviewhandleClose} aria-labelledby="customized-dialog-title" open={Previewopen}>
      <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
        Test Preview
        <IconButton
          aria-label="close"
          onClick={PreviewhandleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
  <TableContainer component={Paper}>
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>Q.No</TableCell>
          <TableCell>Student Answer</TableCell>
          <TableCell>Correct Answer</TableCell>
          <TableCell>Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {responseData ? (
          responseData.length > 0 ? (
            responseData.map((question, index) => (
              <TableRow key={question.question_id}>
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell style={{ cursor: "pointer" }} onClick={CrtClickOpen}>
                  {question.student_answer || ""}
                </TableCell>
                <TableCell style={{ cursor: "pointer" }} onClick={CrtClickOpen}>
                  {question.correct_answer}
                </TableCell>
                <TableCell> 
                {question.student_answer == null || question.student_answer === ""
    ? null
    : question.student_answer === question.correct_answer
    ? <img src={Correct} height="30px" alt="Correct" />
    : <img src={Rong} height="30px" alt="Wrong" />}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4}>
                <Typography>No data available</Typography>
              </TableCell>
            </TableRow>
          )
        ) : (
          <TableRow>
            <TableCell colSpan={4}>
              <Typography>Loading data...</Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
</DialogContent>
<DialogActions style={{ display: "flex", justifyContent: "flex-start" }}>
  <Button style={Btn1} autoFocus onClick={PreviewhandleClose}>
    Ok
  </Button>
  <div style={{ display: "flex", justifyContent: "flex-end", width: "400px" }}>
    <Typography>
      <img src={Web} height="30px" style={{ marginTop: "7px" }} />
    </Typography>
    &nbsp;&nbsp;
    {
  whatsapp.map((d) => {
    return (
      <div key={d.id}>
        <a
          href={d.whatsapplink}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: "none",
          }}
        >
          <div
            style={{
              backgroundColor: "#183A1D",
              color: "white",
              height: "40px",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {d.whatsapplink}
          </div>
        </a>
      </div>
    );
  })
}

  </div>
</DialogActions>
</Dialog>
    </div>
      
                </div>
            </div>
           
    
          </Col>
        </Row>
      </Container>
      
    </div>
    </div>
    
    
  );

}

export default SubTest;