import React from "react";
import { Container, Row,Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Test from '../../asserts/flexibility.png'
import Subject from '../../asserts/test.png'
import Discussion from '../../asserts/communication.png'
import MCQ from '../../asserts/online.png'
import Note from '../../asserts/test (2).png'
import Test1 from '../../asserts/test (1).png'
import "./style.css";
import { Button, Typography,  } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Crown from '../../asserts/crown.png'
import { useState,useContext,useEffect } from "react";
import { UserContext } from "../../Context";
import { axiosInstance } from "../Api/instance";
import Prelims from '../../asserts/aiims.png';
import Mains from '../../asserts/mains.png'
const MyComponent = () => {

const [Cardstyle,setCardstyle]=React.useState(true);
const { Endpoint } = useContext(UserContext);
const [category,setCategory]=useState([]);
const email=localStorage.getItem("userMail");
const Navigate=useNavigate();
const plan=localStorage.getItem("category"); 
console.log(plan);

useEffect(() => {
  fetchData1();
}, []);

const fetchData1 = async () => {
  try {
    const response = await axiosInstance.post(
      `get/U_ViewProfile.php`,
      {
        email:email
      }
    );
    setCategory(response.data);
    console.log(category);
  } catch (error) {
   
    console.error("Error fetching data:", error);
  }
};

const CardClick=()=>{
  setCardstyle(false);
}
const Click=()=>{
  const isPremium =
    category.length > 0 && plan === "premium";
    if(isPremium){
      Navigate('/hybrid');
    }
    else{
      alert("Only For Premium Members");
    }
}



  return (
    <>
      <Container fluid style={{ backgroundColor: "#E8F5EC", paddingTop: 20 }}>
        <Row className="text-center justify-content-center text-row">
          <div>
            <h4 className="tittle"> CATEGORY</h4>
          </div>
        </Row>
        <Row className="d-flex justify-content-center lg-p-5 ">
          <Col
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xs={12}
            className="align-items-center p-3"
          >
            <NavLink
              to="/year"
              style={{ textDecoration: "none", color: "black" }}
              onClick={CardClick}
            >
              <div className="box" style={{ backgroundColor: "white" }}>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      visibility: "hidden",
                    }}
                  >
                    <img src={Crown} height="20px" />
                    &nbsp;
                    <Typography style={{ fontSize: "14px" }}>
                      Only for premium
                    </Typography>
                  </div>
                  <img src={Note} height="70px" />
                </div>
                <h5 className="profile-name">Previous MCQ</h5>
              </div>
            </NavLink>
          </Col>
          <Col
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xs={12}
            className="align-items-center p-3"
          >
            <NavLink
              to="/subject"
              style={{ color: "black", textDecoration: "none" }}
            >
              <div className="box" style={{ backgroundColor: "white" }}>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      visibility: "hidden",
                    }}
                  >
                    <img src={Crown} height="20px" />
                    &nbsp;
                    <Typography style={{ fontSize: "14px" }}>
                      Only for premium
                    </Typography>
                  </div>
                  <img src={Subject} height="70px" />
                </div>
                <h5 className="profile-name">Subject_wise MCQ</h5>
              </div>
            </NavLink>
          </Col>
          <Col
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xs={12}
            className="align-items-center p-3"
          >
            <NavLink
              to="/non_nursing"
              style={{ color: "black", textDecoration: "none" }}
            >
              <div className="box" style={{ backgroundColor: "white" }}>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      visibility: "hidden",
                    }}
                  >
                    <img src={Crown} height="20px" />
                    &nbsp;
                    <Typography style={{ fontSize: "14px" }}>
                      Only for premium 
                    </Typography>
                  </div>
                  <img src={MCQ} height="70px" />
                </div>
                <h5 className="profile-name">Non_nursing MCQ</h5>
              </div>
            </NavLink>
          </Col>
        </Row>
      </Container>
      <Container fluid style={{ backgroundColor: "#E8F5EC" }}>
        <Row>
          <Col
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xs={12}
            className="align-items-center p-3"
          >
            <NavLink
              to="/model"
              style={{ color: "black", textDecoration: "none" }}
            >
              <div className="box" style={{ backgroundColor: "white" }}>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      visibility: "hidden",
                    }}
                  >
                    <img src={Crown} height="20px" />
                    &nbsp;
                    <Typography style={{ fontSize: "14px" }}>
                      Only for premium
                    </Typography>
                  </div>
                  <img src={Test1} height="70px" />
                </div>
                <h5 className="profile-name">Model_mock Test</h5>
              </div>
            </NavLink>
          </Col>
          <Col
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xs={12}
            className="align-items-center p-3"
          >
            {/* <NavLink
              to="/hybrid"
              style={{ color: "black", textDecoration: "none" }}
            > */}
              <div className="box" style={{ backgroundColor: "white" }} onClick={Click}>
                <div>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <img src={Crown} height="20px" style={{}} />
                    &nbsp;
                    <Typography style={{ fontSize: "14px" }}>
                      Only for Premium Members
                    </Typography>
                  </div>
                  <img src={Test} height="70px" />
                </div>
                <h5 className="profile-name">Hybrid Test</h5>
              </div>
            {/* </NavLink> */}
          </Col>
          <Col
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xs={12}
            className="align-items-center p-3"
          >
            <NavLink
              to="/discussion"
              style={{ color: "black", textDecoration: "none" }}
            >
              <div className="box" style={{ backgroundColor: "white" }}>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      visibility: "hidden",
                    }}
                  >
                    <img src={Crown} height="20px" />
                    &nbsp;
                    <Typography style={{ fontSize: "14px" }}>
                      Only for premium
                    </Typography>
                  </div>
                  <img src={Discussion} height="70px" />
                </div>
                <h5 className="profile-name">Discussion Forum</h5>
              </div>
            </NavLink>
          </Col>
        </Row>
      </Container>
      {/* <Container fluid style={{ backgroundColor: "#E8F5EC" }}>
        <Row>
          <Col
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xs={12}
            className="align-items-center p-3"
          >
            <NavLink
              to="/prelims"
              style={{ color: "black", textDecoration: "none" }}
            >
              <div className="box" style={{ backgroundColor: "white" }}>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      visibility: "hidden",
                    }}
                  >
                    <img src={Crown} height="20px" />
                    &nbsp;
                    <Typography style={{ fontSize: "14px" }}>
                      Only for premium
                    </Typography>
                  </div>
                  <img src={Prelims} height="70px" />
                </div>
                <h5 className="profile-name">Aiims Prelims</h5>
              </div>
            </NavLink>
          </Col>
          <Col
            xl={4}
            lg={4}
            md={6}
            sm={12}
            xs={12}
            className="align-items-center p-3"
          >
              <NavLink
              to="/mains"
              style={{ color: "black", textDecoration: "none" }}
            >
              <div className="box" style={{ backgroundColor: "white" }}>
                <div>
                  <div style={{ display: "flex", justifyContent: "flex-end", visibility: "hidden", }}>
                    <img src={Crown} height="20px" style={{}} />
                    &nbsp;
                    <Typography style={{ fontSize: "14px" }}>
                      Only for Premium Members
                    </Typography>
                  </div>
                  <img src={Mains} height="70px" />
                </div>
                <h5 className="profile-name">Aiims Mains</h5>
              </div>
              </NavLink>
          
          </Col>
          
        </Row>
      </Container> */}

    </>
  );
};

export default MyComponent;
