import styled from "@emotion/styled";

export const Style = styled("div")(() => ({
    ".MuiButton-root":{
        color:"white"
    },
    ".Option":{
        color:"black",
        borderBottom:"1px solid black",
        marginBottom:"10px"
    },
    ".css-oxts8u-MuiCircularProgress-circle":{
        stroke:"#E4A45A",

    }
  
}));