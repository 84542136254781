
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { axiosInstance } from '../component/Api/instance';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../Context'; 
const CountdownTimer = () => {
    const {timeLeft, setTimeLeft} = useContext(UserContext);
    const email=localStorage.getItem("userMail");
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [part,setPart]=useState(1);
    const navigate=useNavigate();
    // useEffect(() => {
        const fetchTimeDifference = async (time) => {
            try {
               
                        const response = await axiosInstance.post(`get/U_ViewMainsTimer.php`,
                            {
                                userId:email,
                                part:time
                            }
                        );
                        console.log(response.data.time);
                        
                if (!response || !response.data || !response.data.time) {
                    console.error("Invalid API response:", response);
                    return;
                }
                
               
                
                const serverTime = new Date(response.data.time);
                const currentTime = new Date();
                const timeDifference = Math.floor((serverTime - currentTime) / 1000); // difference in seconds
                setTimeLeft(timeDifference > 0 ? timeDifference : 0);
            } catch (error) {
                console.error("Error fetching time difference:", error);
            }
        };

    //     fetchTimeDifference(1);
    // }, []);

    useEffect(() => {
        fetchTimeDifference(part);
    }, [part]);

    useEffect(() => {
        if (timeLeft === 0) {
            setModalIsOpen(true);
            setTimeout(() => {
                setModalIsOpen(false);
                setPart(part+1);
                
            }, []);
            return;
        }

        const timer = timeLeft > 0 && setInterval(() => {
            setTimeLeft(prevTime => prevTime - 1);
        }, 1000);

        return () => clearInterval(timer);
    }, [timeLeft]);

    const formatTime = (seconds) => {
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    return (
        <div>
            {timeLeft !== null ? (
                <p>TIME: {formatTime(timeLeft)}</p>
            ) : (
                <p>Loading...</p>
            )}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                contentLabel="Times Up!"
            >
                <div>
                    <p>Times Up!</p>
                </div>
            </Modal>
        </div>
    );
};

export default CountdownTimer;
