import { Height } from "@material-ui/icons";

// NavbarStyles.js
const styles = {
  appBar: {
    backgroundColor: "white",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.1), 0px 4px 5px 0px rgba(0,0,0,0.06), 0px 1px 10px 0px rgba(0,0,0,0.1)",
  },
  toolbar: {
    justifyContent: "space-between",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
  },
  menuIcon: {
    color: "black",
  },
  menuText: {
    color: "black",
    display:'flex',
    fontFamily: 'Source',
    // right:600
    left:0
  },
  ".css-h4y409-MuiList-root":{
    position:"relative",
    left:40,
  },
  ".MuiDialog-root":{
    
    height:"30px",
    // backgroundColor:'red',
    margin:"20px"
  }
};


export default styles;
