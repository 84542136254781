import { Padding } from "@mui/icons-material";
import Image from "../../../src/asserts/Homebg.jpg";

const styles = {
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    padding:0
    // paddingBottom: "20px",
  },
  // '.MuiContainer-root':{
  //   padding:"0px",
  //   margin:"0px"
  // },
  // button: {
  //   width: "200px",
  //   height: "50px",
  //   marginRight: "10px",
  // },
  startButton: {
    backgroundColor: "#183A1D",
    color: "white",
  },
  premiumButton: {
    backgroundColor: "#F0A04B",
    color: "white",
    fontFamily: 'Source',
  },
  
};

export default styles;
