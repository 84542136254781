import React from 'react'
import CreateAccount from '../createAccount/CreateAccount'
export default function LandingPage() {
  
 
  return (
    <div>
        
        <CreateAccount/>
        
    </div>
  )
}
