import React, { useState, useEffect, useContext } from "react";
import { axiosInstance } from "../component/Api/instance";
import { Container, Row, Col } from "react-bootstrap";
import "../YearTestPaper/style.css";
import { Button } from "@mui/material";
import { Style } from "../YearTestPaper/style";
import Arrow from "../asserts/system-regular-161-trending-flat-white.gif";
import Typography from "@mui/material/Typography";
import Crown from "../asserts/crown.png";
import { useParams, useNavigate } from "react-router-dom";
import { UserContext } from "../Context";

export default function PrelimsInstruction() {
  const navigate = useNavigate();
  const [options, setOptions] = useState([]);
  const [data, setData] = useState([]);
  const [paperid, setPaperid] = useState(null);
  const { sno } = useParams();
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [category, setCategory] = useState([]);
  const plan=localStorage.getItem("category");
  const email = localStorage.getItem("userMail");
  const { Endpoint } = useContext(UserContext);
 
  

  useEffect(() => {
    Institution();
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.post(`get/U_ViewProfile.php`, {
        email: email,
      });
      if (response.status === 200) {
        if (response.data.message === "timeout") {
          navigate('/signin'); 
        } 
        setCategory(response.data);
      }
    
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const Institution = async () => {
    try {
      const response = await axiosInstance.post(
        `get/U_ViewPrelims.php`,
        { userId: email }
      );
      if (response.status === 200) {
        if (response.data.message === "timeout") {
          navigate('/signin'); 
        } 
        const yearobj = response.data
          ?.map((item) => ({
            ...item,
            img: `https://nursingupdate.in/NursingDemo/controllers/api/admin/upload/${item.img}`,
          }))
          .filter((item) => item.sno == sno);
        setData(yearobj);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchPaperData = async () => {
      try {
        const response = await axiosInstance.post(
          `get/U_ViewPrelimsPaper.php`,
          { userId: email, id: sno }
        );
        if (response.status === 200) {
          if (response.data.message === "timeout") {
            navigate('/signin'); 
          } 
          const paperobj = response?.data?.filter(
            (datas) => datas.prelims_id == sno
          );
          setOptions(paperobj);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchPaperData();
  }, []);

  const PaperData = async (index, paperCategory) => {
    const selectedPaper = options[index];

    if (category.length > 0 && options.length > 0) {
      if (plan === "standard" ) {
        try {
          const response = await axiosInstance.post(`post/U_PrelimsTest.php`, {
            id: email,
            prelims_id: sno,
            paper_id: selectedPaper.sno,
          });
          if (response.status === 200) {
            if (response.data.message === "timeout") {
              navigate('/signin'); 
            } 
            setSelectedIndex(index);
            setPaperid(selectedPaper.sno);
          }
        } catch (error) {
          console.error("Error initializing paper:", error);
        }
      } else if (plan == "premium") { 
        try {
          const response = await axiosInstance.post(`post/U_PrelimsTest.php`, {
            id: email,
            prelims_id: sno,
            paper_id: selectedPaper.sno,
          });
          if (response.status === 200) {
            if (response.data.message === "timeout") {
              navigate('/signin'); 
            } 
            setSelectedIndex(index);
            setPaperid(selectedPaper.sno);
          }
        } catch (error) {
          console.error("Error initializing paper:", error);
        }
      } else {
        alert("You are not allowed to access this paper."+plan);
      }
    } else {
      alert("Data is not available to determine access.");
    }
  };

  const startTest = () => {
    if (paperid) {
      const selectedPaper = options[selectedIndex];
      navigate(`/prelimstest/${sno}/${paperid}`, {
        state: {
          paperName: selectedPaper.paper_name,
        },
      });
    }
  };

  const MainContainer = {
    backgroundColor: "#E1EEDD",
    height: "200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  };

  const Btn = {
    backgroundColor: "#F0A04B",
    border: "none",
    borderRadius: "5px",
    fontWeight: 600,
    marginBottom: "10px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
  };

  return (
    <Style>
      {data.length > 0 && data.map((d) => (
        <div key={d.sno} style={{ backgroundColor: "#E1EEDD", height: "100vh" }}>
          <Container fluid style={MainContainer}>
            <Row>
              <Col>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "95vw",
                    padding: "20px",
                  }}
                >
                  <div style={{ width: "700px" }}>
                    <Typography style={{ fontWeight: 600 }}>
                      {d.prelims_name}
                    </Typography>
                    <Typography>{d.prelims_desc}</Typography>
                  </div>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <img src={d.img} height="65px" alt="Institution Logo" />
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <Container fluid style={{ padding: "20px" }}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={3} xl={3}>
                <div style={{ backgroundColor: "white", borderRadius: "10px" }}>
                  <Button fullWidth style={Btn}>
                    Model MCQ
                  </Button>
                  {options.map((option, index) => (
                    <Button
                      key={index}
                      className="Option"
                      fullWidth
                      onClick={() => PaperData(index, option.category)}
                      style={{
                        backgroundColor:
                          selectedIndex === index ? "#183A1D" : "#fff",
                        color: selectedIndex === index ? "#fff" : "#000",
                      }}
                    >
                      {option.paper_name}&nbsp;&nbsp;
                      {option.category === "premium" && (
                        <img src={Crown} height="20px" alt="Crown Icon" />
                      )}
                    </Button>
                  ))}
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={9} xl={9}>
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: "10px",
                    padding: "40px",
                  }}
                >
                  <Typography style={{ fontWeight: 600 }}>INSTRUCTION</Typography>
                  {d.prelims_instruction && (
  <ul>
    {d.prelims_instruction
      .split(/\r\n/)
      .filter(instruction => instruction.trim() !== "") // filter out empty instructions
      .map((instruction, index) => (
        <li key={index} style={{listStyleType:"none"}}>{instruction.trim()}</li>
      ))}
  </ul>
)}

                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    {selectedIndex !== null && (
                      <Button
                        onClick={startTest}  // Only navigate on button click
                        style={{
                          backgroundColor: "#183A1D",
                          color: "white",
                          width: "100px",
                        }}
                        disabled={!paperid}
                      >
                        Start &nbsp;
                        <img src={Arrow} alt="Arrow Icon" height="20px" />
                      </Button>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      ))}
    </Style>
  );
}
