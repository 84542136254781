import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProfileImg from "../../asserts/Component 278 – 1.png";
import { Button, IconButton, Typography } from "@mui/material";
import Footer from "../footer";
import "./style.css";
import CircleBar from "../Common/circleBar";
import Logout from "../../asserts/icons.png";
import close from "../../asserts/close_FILL0_wght400_GRAD0_opsz24 (1).png";

import Email from "../../asserts/email.png";
import phone from "../../asserts/352510_local_phone_icon.png";
import graduate from "../../asserts/Layer 2.png";
import gender from "../../asserts/8673492_ic_fluent_person_available_filled_icon.png";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { Style } from "../../YearTestPaper/style";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { NavLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Editprofile from '../Pages/Editprofile'
import { useState,useEffect,useContext } from "react";
import { axiosInstance } from "../Api/instance";
import { UserContext } from "../../Context";
import { auth } from "../../FirebaseConfig";
import { useNavigate } from "react-router-dom";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default function Profile() {
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState([]);
  const [del,setDel]=useState(false);
  const [subjectData,setsubjectdata]=useState([]);
  const [nonnursingData,setnonnursingdata]=useState([]);
  const {Endpoint}=useContext(UserContext);
  const email=localStorage.getItem("userMail");
  const profile=localStorage.getItem("profile");
  const navigate=useNavigate();
    useEffect(() => {
      fetchData();
      subject();
      nonnursing();
    }, []);

    const fetchData = async () => {
      try {
        const response = await axiosInstance.post(
          `get/U_ViewProfile.php`,
          {
            email:email
          }
        );
        if (response.status === 200) {
          if (response.data.message === "timeout") {
            navigate('/signin'); 
          } 
            setData(response.data);
         
        }
        
        
      } catch (error) {
       
        console.error("Error fetching data:", error);
      }
    };
    const subject = async () => {
      try {
        const response = await axiosInstance.post(
          `get/U_ScoreProfile_SW.php`,
          {
            userId:email
          }
        );
        if (response.status === 200) {
          if (response.data.message === "timeout") {
            navigate('/signin'); 
          }
            setsubjectdata(response.data);
            console.log(subjectData);
          
        }
        
       
  
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const nonnursing = async () => {
      try {
        const response = await axiosInstance.post(
          `get/U_ScoreProfile_NN.php`,
          {
            userId:email
          }
        );
        if (response.status === 200) {
          if (response.data.message === "timeout") {
            navigate('/signin'); 
          } 
            setnonnursingdata(response.data);
            console.log(nonnursingData);
      
        
        }
        
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickOpendel = () => {
    setDel(true);
  };

  const handledelclose=()=>{
    setDel(false);
    navigate("/signup");
  }

  const handledclose=()=>{
    setDel(false);
  }

  const handleLClose=()=>{
    setOpen(false);
  }

  const handleClose =  (value) => {
    try{
       localStorage.removeItem("userMail");
       localStorage.removeItem("token");
       localStorage.removeItem("username");
       localStorage.removeItem("profile");
       auth.signOut();
      setOpen(false);
      navigate("/signin");

    }
    catch{

    }   
  };
  const [opens, setOpens] = React.useState(false);
  const handleOpens = () => setOpens(true);
  const handleClosed = () => setOpens(false);
  const MainContainer = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#E1EEDD",
    height: "40vh",
  };
  const CardDesign = {
    width: "200px",
    borderRadius: "10px",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    textAlign: "center",
    margin: "20px",
  };
  const CardDesign1 = {
    borderRadius: "10px",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    textAlign: "center",
    margin: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: "20px",
  };

  const Div = {
    minWidth: "400px",
    display: "flex",
    flexDirection: "row",
    overflow: "auto",
    padding: "10px",
  };
  const Btn = {
    backgroundColor: "red",
    color: "white",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    
   
  };
  const buttonStyle = {
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  };

  // Define a variable to conditionally apply background image
  const withBackground = {
    backgroundImage: "url('../../asserts/Component 278 – 1.png)", // Adjust the path to your background image
    backgroundSize: "cover", // Adjust background image size
    backgroundPosition: "center", // Adjust background image position
  };

 
  return (
    <Style>
      {
        Array.isArray(data) && data.length > 0 ? (
        data.map((val)=>(
          <div>
        <Dialog onClose={handleClose} open={open}>
          <DialogTitle
            style={{ width: "350px", height: "100px", textAlign: "center" }}
          >
           
            <Button onClick={handleLClose} style={{ width: 1 }}>
              <img
                src={close}
                height="10px"
                style={{ display: "flex", marginLeft: 230 }}
              />
            </Button>
            <hr style={{ width: "100%" }} />
            <Typography>Are you sure want to logout ?</Typography>
            
          </DialogTitle>
          <DialogTitle>
           
              <Button onClick={handleClose} fullWidth style={Btn}>
                yes
              </Button>
           
          </DialogTitle>
        </Dialog>

        <Dialog onClose={handledelclose} open={del}>
        <img
                src={close}
                height="10px"
                width="10px"
                style={{ display: "flex",marginLeft:"380px",marginTop:"10px"}}
                onClick={handledclose} 
              />
        <AccountCircleIcon style={{fontSize:"60px",display:"flex",justifyContent:"center",width:"400px"}}/>
          
          <DialogTitle>
            <hr style={{ width: "100%" }} />
            <Typography style={{padding:"10px",textAlign:"center"}}>Are you sure want to Delete Account?</Typography>
              <Button onClick={handledelclose} fullWidth style={Btn}>
                yes
              </Button>
           
          </DialogTitle>
        </Dialog>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#E1EEDD",
            width: "100%",
            padding:"10px"
           
          }}
        >
          <Button onClick={handleClickOpen}>
            <img src={Logout} height="15px" />&nbsp;
            {"    "}
            <Typography style={{ color: "black", fontSize: 15 }}>
              Logout
            </Typography>
          </Button>
          <DeleteOutlineIcon onClick={handleClickOpendel}/>
        </div>
        <Container fluid style={MainContainer}>
          <Row>
            <Col>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={handleOpens}
                  style={Object.assign({}, buttonStyle, withBackground)}
                >
                  <img
      src={profile ? profile : ProfileImg}
      alt="Profile"
      style={{
        width: '150px',
        height: '150px',
        borderRadius: '50%', 
      }}
    />
                </Button>
                <Modal
                  keepMounted
                  open={opens}
                  onClose={handleClosed}
                  aria-labelledby="keep-mounted-modal-title"
                  aria-describedby="keep-mounted-modal-description"
                >
                 
                  <Editprofile/>
                  
                </Modal>
              </div>
              <div>
                <Typography variant="h4" style={{ textAlign: "center" }}>
                  {val.username}
                </Typography>
              </div>
             
              <Col xs={12} style={{ display: "flex", flexDirection: "row",flexWrap:"wrap" }}>
                <div>
                  <Typography style={{ textAlign: "center", marginTop: 10 }}>
                    <img src={Email} height="15px" />
                    {"   "}
                    {val.email}
                  </Typography>
                </div>
                <div>
                  <Typography
                    style={{
                      textAlign: "center",
                      marginLeft: 10,
                      marginTop: 10,
                    }}
                  >
                    <img src={phone} height="15px" />
                    {"   "}
                   {val.mobileno}
                  </Typography>
                </div>
                <div>
                  <Typography
                    style={{
                      textAlign: "center",
                      marginLeft: 10,
                      marginTop: 10,
                    }}
                  >
                    <img src={graduate} height="15px" />
                    {"   "}
                   {val.qualification}
                  </Typography>
                </div>
                <div>
                  <Typography
                    style={{
                      textAlign: "center",
                      marginLeft: 10,
                      marginTop: 10,
                    }}
                  >
                    <img src={gender} height="15px" />
                    {"  "}
                   {val.gender}
                  </Typography>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row style={{ overflow: "hidden" }}>
            <Typography
              style={{ fontSize: "16px", fontWeight: 600, paddingTop: "20px" }}
            >
              My Activity
            </Typography>
            <div style={Div}>
            {subjectData.map((e) => {
                const percentage = ((e.total_correct_count / e.total_answers_count) * 100).toFixed(2);
                return (
                  <>
                    <Col>
                      <div style={CardDesign}>
                        <Typography
                          style={{ paddingTop: "10px", fontWeight: 600 }}
                        >
                          {e.subject_name}
                        </Typography>
                        <hr />
                        <Typography style={{ fontSize: "12px" }}>
                          {e.total_correct_count}/{e.total_answers_count}
                        </Typography>
                        <div style={{ marginTop: "10px" }}>
                          <CircleBar range={percentage} />
                        </div>
                      </div>
                    </Col>
                  </>
                );
              })}
            </div>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            <div style={Div}>
            {nonnursingData.map((r) => {
               const percentage = ((r.total_correct_count / r.total_answers_count) * 100).toFixed(2);
               console.log(percentage);
              return (
                <>
                  <Col
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    xl={4}
                    style={{ flexDirection: "row" }}
                  >
                    <div style={CardDesign1}>
                      <Typography style={{ fontWeight: 600 }}>
                        {r.subject_name}
                      </Typography>
                      <hr
                        style={{
                          borderLeft: "1px solid black",
                          height: "100px",
                        }}
                      />
                      <div style={{ flexDirection: "column" }}>
                        <Typography style={{ fontSize: "12px" }}>
                        {r.total_correct_count}/{r.total_answers_count} 
                        </Typography>
                        <div style={{ marginTop: "10px" }}>
                          <CircleBar range={percentage} />
                        </div>
                      </div>
                    </div>
                  </Col>
                </>
              );
            })}
            </div>
          </Row>
        </Container>
        <Footer />
      </div>
        ))):(
          <Typography>No datas</Typography>
        )
      }
    </Style>
  );
}
